var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-box', {
    staticClass: "selectdashboardtype",
    attrs: {
      "title": _vm.$t('dashboards.createDashboard.newDashboard')
    }
  }, [_c('v-row', {
    staticClass: "selectdashboardtype--cards-container"
  }, [_c('v-col', {
    staticClass: "selectdashboardtype--rdtcard-container"
  }, [_c('r-dashboard-type-card', {
    class: {
      'selectdashboardtype--selected': _vm.selectedDashboardType === _vm.TYPES.SINGLE_PRODUCT
    },
    attrs: {
      "type": _vm.TYPES.SINGLE_PRODUCT
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardType(_vm.TYPES.SINGLE_PRODUCT);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "selectdashboardtype--rdtcard-container"
  }, [_c('r-dashboard-type-card', {
    class: {
      'selectdashboardtype--selected': _vm.selectedDashboardType === _vm.TYPES.SINGLE_CATEGORY
    },
    attrs: {
      "type": _vm.TYPES.SINGLE_CATEGORY
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardType(_vm.TYPES.SINGLE_CATEGORY);
      }
    }
  })], 1), _vm.isSTDEnabled ? _c('v-col', {
    staticClass: "selectdashboardtype--rdtcard-container"
  }, [_c('r-dashboard-type-card', {
    class: {
      'selectdashboardtype--selected': _vm.selectedDashboardType === _vm.TYPES.SINGLE_TAG
    },
    attrs: {
      "type": _vm.TYPES.SINGLE_TAG
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardType(_vm.TYPES.SINGLE_TAG);
      }
    }
  })], 1) : _vm._e(), _vm.isSBDEnabled ? _c('v-col', {
    staticClass: "selectdashboardtype--rdtcard-container"
  }, [_c('r-dashboard-type-card', {
    class: {
      'selectdashboardtype--selected': _vm.selectedDashboardType === _vm.TYPES.SINGLE_BRAND
    },
    attrs: {
      "type": _vm.TYPES.SINGLE_BRAND
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardType(_vm.TYPES.SINGLE_BRAND);
      }
    }
  })], 1) : _vm._e(), _vm.isMPDEnabled ? _c('v-col', {
    staticClass: "selectdashboardtype--rdtcard-container"
  }, [_c('r-dashboard-type-card', {
    class: {
      'selectdashboardtype--selected': _vm.selectedDashboardType === _vm.TYPES.MULTI_PRODUCT
    },
    attrs: {
      "type": _vm.TYPES.MULTI_PRODUCT
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardType(_vm.TYPES.MULTI_PRODUCT);
      }
    }
  })], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "selectdashboardtype--buttons-container"
  }, [_c('v-col', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('div', [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "outlined": true,
      "label": _vm.$t('global.actions.cancel')
    },
    on: {
      "click": _vm.onCancel
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.$t('global.actions.continue'),
      "disabled": !_vm.isDashboardTypeSelected
    },
    on: {
      "click": _vm.onContinue
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }