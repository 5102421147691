<template>
  <r-box
    class="selectdashboardtype"
    :title="$t('dashboards.createDashboard.newDashboard')"
  >
    <v-row class="selectdashboardtype--cards-container">
      <v-col class="selectdashboardtype--rdtcard-container">
        <r-dashboard-type-card
          :type="TYPES.SINGLE_PRODUCT"
          :class="{
            'selectdashboardtype--selected':
              selectedDashboardType === TYPES.SINGLE_PRODUCT
          }"
          @click="setDashboardType(TYPES.SINGLE_PRODUCT)"
        />
      </v-col>

      <v-col class="selectdashboardtype--rdtcard-container">
        <r-dashboard-type-card
          :type="TYPES.SINGLE_CATEGORY"
          :class="{
            'selectdashboardtype--selected':
              selectedDashboardType === TYPES.SINGLE_CATEGORY
          }"
          @click="setDashboardType(TYPES.SINGLE_CATEGORY)"
        />
      </v-col>

      <v-col
        v-if="isSTDEnabled"
        class="selectdashboardtype--rdtcard-container"
      >
        <r-dashboard-type-card
          :type="TYPES.SINGLE_TAG"
          :class="{
            'selectdashboardtype--selected':
              selectedDashboardType === TYPES.SINGLE_TAG
          }"
          @click="setDashboardType(TYPES.SINGLE_TAG)"
        />
      </v-col>

      <v-col
        v-if="isSBDEnabled"
        class="selectdashboardtype--rdtcard-container"
      >
        <r-dashboard-type-card
          :type="TYPES.SINGLE_BRAND"
          :class="{
            'selectdashboardtype--selected':
              selectedDashboardType === TYPES.SINGLE_BRAND
          }"
          @click="setDashboardType(TYPES.SINGLE_BRAND)"
        />
      </v-col>

      <v-col
        v-if="isMPDEnabled"
        class="selectdashboardtype--rdtcard-container"
      >
        <r-dashboard-type-card
          :type="TYPES.MULTI_PRODUCT"
          :class="{
            'selectdashboardtype--selected':
              selectedDashboardType === TYPES.MULTI_PRODUCT
          }"
          @click="setDashboardType(TYPES.MULTI_PRODUCT)"
        />
      </v-col>
    </v-row>
    <v-row class="selectdashboardtype--buttons-container">
      <v-col class="d-flex justify-end align-center">
        <div>
          <r-button
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="onCancel"
          />
          <r-button
            :label="$t('global.actions.continue')"
            :disabled="!isDashboardTypeSelected"
            @click="onContinue"
          />
        </div>
      </v-col>
    </v-row>
  </r-box>
</template>

<script>
import { mapGetters } from 'vuex'
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import RDashboardTypeCard from './common/RDashboardTypeCard'

export default {
  name: 'SelectDashboardType',
  components: {
    RBox,
    RDashboardTypeCard,
    RButton,
  },
  data: () => ({
    selectedDashboardType: '',
  }),
  computed: {
    ...mapGetters('users', [
      'hasAppFeature',
    ]),
    isMPDEnabled() {
      return this.hasAppFeature('beta/mpd')
    },
    isSTDEnabled() {
      return this.hasAppFeature('beta/std')
    },
    isSBDEnabled() {
      return this.hasAppFeature('beta/sbd')
    },
    isDashboardTypeSelected() {
      return (this.selectedDashboardType === this.TYPES.SINGLE_PRODUCT
      || this.selectedDashboardType === this.TYPES.SINGLE_CATEGORY
      || this.selectedDashboardType === this.TYPES.SINGLE_TAG
      || this.selectedDashboardType === this.TYPES.SINGLE_BRAND
      || this.selectedDashboardType === this.TYPES.MULTI_PRODUCT)
    },
  },
  created() {
    this.TYPES = TYPES_OF_DASHBOARD
  },
  methods: {
    setDashboardType(type) {
      this.selectedDashboardType = type
    },
    onCancel() {
      this.$router.push('/dashboards')
    },
    onContinue() {
      this.$router.push(`/dashboards/add/${this.selectedDashboardType}`)
    },
  },
}
</script>

<style scoped>
.selectdashboardtype {
  margin: 32px auto;
  width: min-content;
}

.selectdashboardtype--cards-container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
}

.selectdashboardtype--rdtcard-container {
  padding: 16px;
  display: grid;
  justify-content: center;
}

.selectdashboardtype--buttons-container {
  padding: 0 16px 0;
}

.selectdashboardtype--selected {
  border: 1px solid #1691CF;
}
</style>
